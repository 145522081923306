import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, message, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import { ROUTES, TABS } from '@common/constants';
import { fileUpload, getSignedUrl } from '@common/utils';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import history from '../../../historyData';
import OrganizationForm from '../components/OrganizationForm';

import ModalComponent from '../../../components/ModalComponent';
import OrganizationTab from '../components/OrganizationTab';
import {
  CREATE_ORGANIZATION,
  EXPORT_COMPANY_CONFIG,
  IMPORT_COMPANY_CONFIG,
  UPDATE_ORGANIZATION,
} from '../graphql/Mutations';
import {
  GET_CONFIG_UPLOAD_SIGNED_URL,
  GET_ORGANIZATION_DETAIL,
  GET_SIGNED_URL,
} from '../graphql/Queries';

const OrganizationDetails = () => {
  const { id: organizationId, mode } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [organizationLogo, setOrganizationLogo] = useState([]);
  const [isView, setIsView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [importLoading, setImportLoading] = useState(false);
  const [createOrganization, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onError: () => {},
    },
  );

  const [
    updateOrganization,
    { loading: updateLoading },
  ] = useMutation(UPDATE_ORGANIZATION, { onError: () => {} });

  const [getOrganizationData, { data: organizationData }] = useLazyQuery(
    GET_ORGANIZATION_DETAIL,
  );

  const onEdit = () => {
    setIsView(false);
    history?.push(
      `${ROUTES?.ORGANIZATIONS}/edit/${organizationId}/${TABS?.ORGANIZATION?.key}`,
    );
  };

  const onCancelEdit = () => {
    setIsView(true);
    history?.push(`${ROUTES?.ORGANIZATIONS}`);
  };

  const onFinish = async (values) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });

    const dataToSend = {
      ...values,
    };
    try {
      let key;
      if (values?.logoUrl) {
        if (values?.logoUrl?.fileList?.length > 0) {
          await getSignedUrl(values?.logoUrl?.file, GET_SIGNED_URL)?.then(
            async (res) => {
              const signedImageUrl = res?.getCompanyUploadSignedUrl?.signedUrl;
              key = res?.getCompanyUploadSignedUrl?.key;
              await fileUpload(
                signedImageUrl,
                values?.logoUrl?.fileList?.[0]?.originFileObj,
              );
              dataToSend.logo = key;
            },
          );
        } else if (values?.logoUrl?.file?.status === 'removed') {
          dataToSend.logo = null;
        } else {
          delete dataToSend.logo;
        }
      }
      const { logoUrl, email, slug, ...restValues } = dataToSend;
      if (organizationId) {
        try {
          await updateOrganization({
            variables: {
              where: { id: organizationId },
              data: { ...restValues },
            },
            onComerrorpleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      } else {
        try {
          await createOrganization({
            variables: {
              data: { ...restValues, email, slug },
            },
            onCompleted: () => onCancelEdit(),
          });
        } catch (error) {
          return error;
        }
      }
    } catch (error) {
      return error;
    }
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const getOrganizationDetail = async () => {
    try {
      await getOrganizationData({
        variables: { where: { id: organizationId } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationDetail();
    }
    if (mode === 'view') {
      setIsView(true);
    }
  }, []);

  useEffect(() => {
    if (organizationData) {
      const organizationValue = {
        name: organizationData?.getCompany?.name,
        email: organizationData?.getCompany?.email,
        logoUrl: organizationData?.getCompany?.logo,
        url: organizationData?.getCompany?.url,
        slug: organizationData?.getCompany?.slug,
      };
      form?.setFieldsValue(organizationValue);
      setOrganizationLogo(
        organizationData?.getCompany?.logo
          ? [{ url: organizationData?.getCompany?.logo }]
          : [],
      );
    }
  }, [organizationData]);

  const [
    exportCompanyConfig,
    { loading: exportConfigLoading },
  ] = useMutation(EXPORT_COMPANY_CONFIG, { onError: () => {} });
  const [importCompanyConfig] = useMutation(IMPORT_COMPANY_CONFIG, {
    onCompleted: () => {
      setImportLoading(false);
      setFileData(null);
      setIsModalOpen(false);
    },
    onError: () => {
      setImportLoading(false);
    },
  });

  const [getCompanyConfigSignedUrl] = useLazyQuery(
    GET_CONFIG_UPLOAD_SIGNED_URL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const onImport = async () => {
    if (fileData) {
      setImportLoading(true);
      const { file, fileList } = fileData;
      const res = await getCompanyConfigSignedUrl({
        variables: {
          input: {
            companyId: organizationId,
            fileName: file?.name,
          },
        },
      });

      if (res?.data) {
        await fileUpload(
          res?.data?.getCompanyConfigsUploadSignedUrl?.signedUrl,
          fileList?.[0]?.originFileObj,
        );
        importCompanyConfig({
          variables: {
            input: {
              companyId: organizationId,
              fileKey: res?.data?.getCompanyConfigsUploadSignedUrl?.key,
            },
          },
        });
      } else {
        setImportLoading(false);
      }
    } else {
      message.error('Please select a file to import');
    }
  };
  const onExport = async () => {
    try {
      await exportCompanyConfig({
        variables: {
          where: { companyId: organizationId },
        },
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <CommonHeader
        heading={
          organizationId
            ? `${mode} Organisation Detail`
            : 'Add New Organisation Detail'
        }
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <div className="header-btn-wrapper d-flex">
          <Button
            className="common-button mr-16"
            size="middle"
            onClick={onExport}
            loading={exportConfigLoading}
          >
            Export Configs
          </Button>
          <Button
            className="common-button mr-16"
            onClick={() => setIsModalOpen(true)}
            size="middle"
          >
            Import Configs
          </Button>

          {isView ? (
            <Button className="common-button" size="middle" onClick={onEdit}>
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                onClick={onCancelEdit}
              >
                Cancel
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form?.submit}
                loading={createLoading || updateLoading}
                disabled={createLoading || updateLoading || isDisabled}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </CommonHeader>
      <OrganizationTab>
        <OrganizationForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          organizationId={organizationId}
          organizationLogo={organizationLogo}
          setOrganizationLogo={setOrganizationLogo}
          isView={isView}
        />
      </OrganizationTab>

      <ModalComponent
        title="Import Configs"
        open={isModalOpen}
        okText="import"
        cancelText="Cancel"
        onOk={onImport}
        onCancel={() => {
          setFileData(null);
          setImportLoading(false);
          setIsModalOpen(false);
        }}
        confirmLoading={importLoading}
      >
        <div className="import-config-wrapper">
          <div>Select a File with existing configs</div>
          <Upload
            name="import-config"
            fileList={fileData?.fileList}
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            accept=".json"
            onChange={(data) => setFileData(data)}
            showUploadList={{
              showDownloadIcon: false,
              showPreviewIcon: false,
              showRemoveIcon: false,
            }}
            disabled={importLoading}
          >
            <div className="d-flex flex-vertical align-center">
              <PlusOutlined /> Upload
            </div>
          </Upload>
        </div>
      </ModalComponent>
    </>
  );
};

export default OrganizationDetails;
