import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  BOOK_SURVEY_CONFIG,
  BUTTON_CONFIG,
  EMPTY_STATE_IMAGES_KEYS,
  FLOW_VISIBILITY_CONFIG,
  LAYOUT_CONFIG,
  ROUTES,
  TABS,
  THEME_CONFIG,
  TOOLTIP_ICON_CONFIG,
  WAIT_LIST_CONFIG,
} from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import history from '../../../historyData';
import OrganizationConfigForm from '../components/OrganizationConfigForm';
import OrganizationTab from '../components/OrganizationTab';
import {
  CREATE_ORGANIZATION_CONFIG,
  UPDATE_ORGANIZATION_CONFIG,
} from '../graphql/Mutations';
import { GET_ORGANIZATION_CONFIG } from '../graphql/Queries';

const OrganizationConfiguration = () => {
  const { id: organizationId, mode } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [themeConfig, setThemeConfig] = useState(THEME_CONFIG);
  const [buttonsConfig, setButtonsConfig] = useState(BUTTON_CONFIG);
  const [waitListConfig, setWaitListConfig] = useState(WAIT_LIST_CONFIG);
  const [visibilityConfig, setVisibilityConfig] = useState(
    FLOW_VISIBILITY_CONFIG,
  );
  const [tooltipIconConfig, setTootipIconCofig] = useState(TOOLTIP_ICON_CONFIG);

  const [layoutConfig, setLayoutConfig] = useState(LAYOUT_CONFIG);
  const [bookSurveyConfig, setBookSurveyConfig] = useState(BOOK_SURVEY_CONFIG);
  const [selectedFont, setSelectedFont] = useState(null);
  const [selectedSecondaryFont, setSelectedSecondaryFont] = useState(null);
  const [selectedDefaultSort, setSelectedDefaultSort] = useState(null);
  const [selectedEmailPlace, setSelectedEmailPlace] = useState(0);
  const [isView, setIsView] = useState(false);
  const [trackingId, setTrackingId] = useState(null);
  const [emptyImagesState, setEmptyImagesState] = useState([
    {
      key: EMPTY_STATE_IMAGES_KEYS.RECOMMENDATIONS_PAGE,
      visibility: true,
    },
    {
      key: EMPTY_STATE_IMAGES_KEYS.UPGRADES_PAGE,
      visibility: true,
    },
  ]);
  const [
    suggestedUpgradeTextCenterAlign,
    setSuggestedUpgradeTextCenterAlign,
  ] = useState(false);

  const [createOrganizationConfig, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION_CONFIG,
    {
      onError: () => {},
    },
  );

  const [
    updateOrganizationConfig,
    { loading: updateLoading },
  ] = useMutation(UPDATE_ORGANIZATION_CONFIG, { onError: () => {} });

  const [
    getOrganizationConfig,
    { data: organizationConfigData },
  ] = useLazyQuery(GET_ORGANIZATION_CONFIG);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const getOrganizationConfigDetail = async () => {
    try {
      await getOrganizationConfig({
        variables: { where: { companyId: organizationId } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationConfigDetail();
    }
    if (mode === 'view') {
      setIsView(true);
    }
  }, []);

  const onEdit = () => {
    setIsView(false);
    history?.push(
      `${ROUTES?.ORGANIZATIONS}/edit/${organizationId}/${TABS?.ORGANIZATION_CONFIGURATION?.key}`,
    );
  };

  const onCancelEdit = (redirectFromEdit) => {
    setIsView(true);
    if (!redirectFromEdit) {
      history?.push(`${ROUTES?.ORGANIZATIONS}`);
    }
  };

  const onFinish = async () => {
    const themeColors = {};
    _.forEach(themeConfig, (category) => {
      _.forEach(category?.colors, (color) => {
        _.set(themeColors, color?.name, color?.value);
      });
    });

    const buttonAllConfig = {};
    _.forEach(buttonsConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(buttonAllConfig, config?.name, config?.value);
      });
    });

    const layoutAllConfig = {};
    _.forEach(layoutConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(layoutAllConfig, config?.name, config?.value);
      });
    });

    const bookSurveyAllConfig = {};
    _.forEach(bookSurveyConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(bookSurveyAllConfig, config?.name, config?.value);
      });
    });

    const waitListAllConfig = {};
    _.forEach(waitListConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(waitListAllConfig, config?.name, config?.value);
      });
    });

    const visibilityAllConfig = {};
    _.forEach(visibilityConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(visibilityAllConfig, config?.name, config?.value);
      });
    });

    const updateTooltipIconConfig = {};
    _.forEach(tooltipIconConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(updateTooltipIconConfig, config?.name, config?.value);
      });
    });

    const fonts = {
      fontFamily: selectedFont,
      secondaryFontFamily: selectedSecondaryFont,
    };

    const formSteps = [
      {
        key: 'EMAIL',
        order: selectedEmailPlace,
      },
    ];

    const analyticsConfig = { key: trackingId || null };

    const dataToPass = {
      fonts,
      formSteps,
      themeColors,
      buttons: buttonAllConfig,
      waitList: waitListAllConfig,
      images: emptyImagesState,
      analytics: analyticsConfig,
      updateEpcCta: visibilityAllConfig,
      layout: layoutAllConfig,
      bookSurvey: bookSurveyAllConfig,
      recommendationsDefaultSort: selectedDefaultSort,
      tooltip: {
        icon: updateTooltipIconConfig,
      },
      alignment: {
        suggestedUpgradeTextCenterAlign,
      },
    };

    if (organizationConfigData?.getCompanyConfig) {
      try {
        await updateOrganizationConfig({
          variables: {
            where: { id: organizationConfigData?.getCompanyConfig?.id },
            data: {
              ...dataToPass,
            },
          },
          onCompleted: () => onCancelEdit(true),
        });
      } catch (error) {
        return error;
      }
    } else {
      if (!trackingId) {
        delete dataToPass.analytics;
      }
      try {
        await createOrganizationConfig({
          variables: {
            data: { ...dataToPass, companyId: organizationId },
          },
          onCompleted: () => onCancelEdit(),
        });
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <>
      <CommonHeader
        heading={
          organizationConfigData?.getCompanyConfig
            ? `${mode} Organisation Configuration`
            : 'Add Organisation Configuration'
        }
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <div className="header-btn-wrapper">
          {isView ? (
            <Button className="common-button" size="middle" onClick={onEdit}>
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                onClick={onCancelEdit}
              >
                Cancel
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form?.submit}
                loading={createLoading || updateLoading}
                disabled={createLoading || updateLoading || isDisabled}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </CommonHeader>
      <OrganizationTab>
        <OrganizationConfigForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          organizationConfigData={organizationConfigData?.getCompanyConfig}
          themeConfig={themeConfig}
          buttonsConfig={buttonsConfig}
          setButtonsConfig={setButtonsConfig}
          layoutConfig={layoutConfig}
          setLayoutConfig={setLayoutConfig}
          bookSurveyConfig={bookSurveyConfig}
          setBookSurveyConfig={setBookSurveyConfig}
          setThemeConfig={setThemeConfig}
          waitListConfig={waitListConfig}
          setWaitListConfig={setWaitListConfig}
          visibilityConfig={visibilityConfig}
          setVisibilityConfig={setVisibilityConfig}
          tooltipIconConfig={tooltipIconConfig}
          setTootipIconCofig={setTootipIconCofig}
          selectedFont={selectedFont}
          setSelectedFont={setSelectedFont}
          selectedSecondaryFont={selectedSecondaryFont}
          setSelectedSecondaryFont={setSelectedSecondaryFont}
          selectedEmailPlace={selectedEmailPlace}
          setSelectedEmailPlace={setSelectedEmailPlace}
          isView={isView}
          trackingId={trackingId}
          setTrackingId={setTrackingId}
          emptyImagesState={emptyImagesState}
          setEmptyImagesState={setEmptyImagesState}
          selectedDefaultSort={selectedDefaultSort}
          setSelectedDefaultSort={setSelectedDefaultSort}
          suggestedUpgradeTextCenterAlign={suggestedUpgradeTextCenterAlign}
          setSuggestedUpgradeTextCenterAlign={
            setSuggestedUpgradeTextCenterAlign
          }
        />
      </OrganizationTab>
    </>
  );
};

export default OrganizationConfiguration;
